<template>
    <div class="box">
        <div id="myEchart1" ref="myEchart1" style="width:100%;height:100%"></div>
    </div>
</template>
<script setup>
import { Bottom } from '@element-plus/icons-vue';
import * as echarts from 'echarts'
import { onMounted } from 'vue';
import i18n from '@/data/i18n/index'
const t = i18n.global.t
onMounted(() => {
    const myEchartDiv = document.getElementById('myEchart1')
    const myEchart = echarts.init(myEchartDiv)
    let option = {}
    option = {
        tooltip: {
            trigger: 'item',

        },
        legend: {
            top: '3%',
            left: 'center',
            textStyle: {
                fontSize: 12,
                color: '#FFFFFF',
            },

        },
        series: [
            {
                name: '统计',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                // padAngle: 5,
                itemStyle: {
                    borderRadius: 5
                },
                label: {
                    show: false,
                    position: 'center'
                },
                // emphasis: {
                //     label: {
                //         show: true,
                //         fontSize: 40,
                //         fontWeight: 'bold'
                //     }
                // },
                labelLine: {
                    show: false
                },
                center: ['50%', '55%'],
                data: [
                    {
                        value: 1048, name: t('bigScreen.online'), itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [
                                    { offset: 1, color: '#089ee1' },
                                    { offset: 0, color: '#05eaf4' }
                                ]
                            )
                        },
                    },
                    {
                        value: 735, name: t('bigScreen.offline'), itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [
                                    { offset: 1, color: '#ab4245' },
                                    { offset: 0, color: '#f9afbe' }
                                ]
                            )

                        },
                    },
                    {
                        value: 580, name: t('bigScreen.not_active'), itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [
                                    { offset: 1, color: 'gray' },
                                    { offset: 0, color: '#cfcece' }
                                ]
                            )
                        },
                    },

                ]
            }
        ]
    };
    setTimeout(() => {
        myEchart.setOption(option)
    }, 300)

    window.addEventListener("resize", function (event) {
        myEchart.resize()
    })
})

</script>
<style lang="scss" scoped>
.box {
    color: red;
    height: 100%;
}
</style>