<template>
    <div class="box">
        <div id="myEchart2" class="myEchart2" ref="myEchart2" style="height:100%">
            <div class="c1">
                <div class="up">
                    <div class="up1">{{ $t('bigScreen.online') }}</div>
                    <div class="up2">90%</div>
                    <div class="up3">45678</div>
                </div>
                <div class="bottom">
                    <div class="inner" style="width:90%"></div>
                </div>
            </div>
            <div class="c2">
                <div class="up">
                    <div class="up1">{{ $t('bigScreen.offline') }}</div>
                    <div class="up2">5%</div>
                    <div class="up3">153</div>
                </div>
                <div class="bottom">
                    <div class="inner" style="width:5%"></div>
                </div>
            </div>
            <div class="c3">
                <div class="up">
                    <div class="up1">{{ $t('bigScreen.not_active') }}</div>
                    <div class="up2">5%</div>
                    <div class="up3">153</div>
                </div>
                <div class="bottom">
                    <div class="inner" style="width:5%"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { Bottom } from '@element-plus/icons-vue';
import * as echarts from 'echarts'
import { onMounted } from 'vue';
import i18n from '@/data/i18n/index'
const t = i18n.global.t
onMounted(() => {
    let option = {}

})

</script>
<style lang="scss" scoped>
.box {
    color: #FFFFFF;
    height: 100%;
    width: 100%;

    .myEchart2 {
        padding: 0 .375rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .c1,
        .c2,
        .c3 {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            padding-bottom: 10px;

            >.up {
                display: flex;
                width: 90%;

                .up1 {
                    flex: 1;
                    min-width: 80px;
                }

                .up2 {
                    flex: 3;
                    color: #7feeed;
                }

                .up3 {
                    flex: 5;
                    color: #7feeed;
                    text-align: right;
                }
            }

            >.bottom {
                height: 10px;
                width: 100%;
                margin-top: 2px;
                background-color: #093067;
                border-radius: 5px;

                >.inner {
                    width: 100%;
                    height: 10px;
                    background-color: #04daf0;
                    background: linear-gradient(to right, #089ee1, #05eaf4);
                    border-radius: 5px;
                }
            }
        }

        .c2 {
            >.bottom {

                >.inner {
                    background: linear-gradient(to right, #ab4245, #f9afbe);
                }
            }
        }

        .c3 {
            >.bottom {

                >.inner {
                    background: linear-gradient(to right, gray, #cfcece)
                }
            }
        }
    }
}
</style>